import Image from 'next/image'
import {MenuLink} from '../../../types/content-types'
import GenericLink from '../../shared/Link'

type Props = {
  item: MenuLink
}

const NavigationHighlightComp = ({item}: Props) => {
  const {field_highlight_link, field_highlight_text, field_highlight_title} =
    item

  if (!item) {
    return null
  }

  return (
    <div className="c-navbar__subnav-banner bg-tuna-grey  p-0 ml-auto">
      <div className="c-navbar__subnav-banner__image position-relative py-5 w-100">
        {item?.field_highlight_media?.field_media_image?.uri?.url ? (
          <Image
            src={`${item?.field_highlight_media.field_media_image.uri.url}`}
            alt={
              item.field_highlight_media?.field_media_image?.resourceIdObjMeta
                ?.alt ?? ''
            }
            className="object-fit-cover"
            fill
            sizes="(max-width: 991px) 100vw, 33vw"
          />
        ) : null}
      </div>
      <div key={item.id} className="c-navbar__subnav-banner-textWrapper">
        <div className="d-flex flex-column">
          <span className="c-navbar__subnav-banner-textWrapper__title text-orange">
            {field_highlight_title}
          </span>
          <p className="c-navbar__subnav-banner-textWrapper__description text-white mb-3">
            {field_highlight_text}
          </p>
          <div className="c-navbar__subnav-banner-textWrapper__link ">
            <GenericLink link={field_highlight_link} extraClass="text-orange">
              {field_highlight_link?.title}
            </GenericLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavigationHighlightComp
