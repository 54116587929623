const localeCountryMap = {
  'nl-be': 'belgium_jobs',
  'fr-be': 'belgium_jobs',
  'en-be': 'belgium_jobs',
  'nl-nl': 'netherlands_jobs',
  'en-nl': 'netherlands_jobs',
}



const countryLocaleMap = {
  belgium: 'BE',
  germany: 'DE',
  netherlands: 'NL',
  france: 'FR',
}

const richTextStylesRegex = /(style=".+?")/gm

const jobsPerPage2Panes = 15

export {
  localeCountryMap,
  richTextStylesRegex,
  countryLocaleMap,
  jobsPerPage2Panes,
}

export const carouselConfig = {
  superLargeDesktop: {
    breakpoint: {max: 1920, min: 1200},
    items: 1,
  },
  desktop: {
    breakpoint: {max: 1199, min: 992},
    items: 1,
  },
  tablet: {
    breakpoint: {max: 991, min: 768},
    items: 1,
  },
  mobile: {
    breakpoint: {max: 767, min: 0},
    items: 1,
  },
}
